import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {NbAuthJWTToken, NbAuthService} from '@nebular/auth';
import {tap} from 'rxjs/operators';
import {TokenService} from '../services/token.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        private authService: NbAuthService,
        private router: Router
    ) {
    }

    canActivate() {
        return this.authService.isAuthenticated()
            .pipe(
                tap(authenticated => {
                    if (!authenticated) this.router.navigate(['auth/login']);

                    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
                        if (token.isValid()) {
                            if (token.getPayload()['ott_cookie_id'] != '20231017a') {
                                this.router.navigate(['auth/login']);
                            }
                        } else {
                            this.router.navigate(['auth/login']);
                        }
                    });
                }),
            );
    }
}


@Injectable()
export class AuthGuardAdmin implements CanActivate {

    constructor(
        private authService: NbAuthService,
        private tokenService: TokenService,
        private router: Router) {
    }

    canActivate() {
        return this.authService.isAuthenticated()
            .pipe(
                tap(authenticated => {
                    if (!authenticated) {
                        this.router.navigate(['auth/login']);
                    } else {
                        let role = this.tokenService.role.getValue();
                        if (role == 10) this.router.navigate(['/docs/landers']);
                        else if (role == 7 || role == 8) this.router.navigate(['/chatting']);
                        else if (role != 1) this.router.navigate(['/profiles']);
                        else this.router.navigate(['/'])
                    }
                    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
                        if (token.isValid()) {
                            if (token.getPayload()['ott_cookie_id'] != '20231017a') {
                                this.router.navigate(['auth/login']);
                            }
                        } else {
                            this.router.navigate(['auth/login']);
                        }
                    });
                }),
            );
    }
}

@Injectable()
export class AuthGuardSystemMaster implements CanActivate {

    constructor(private authService: NbAuthService,
                private tokenService: TokenService,
                private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.authService.isAuthenticated()
            .pipe(
                tap(authenticated => {
                    if (!authenticated) {
                        this.router.navigate(['auth/login']);
                    } else {
                        let role = this.tokenService.role.getValue();
                         if ([3, 4].includes(role) && state.url.startsWith('/users')) {
                            return
                        }
                        // escape for admin
                        if ([1, 2].includes(role)) return;
                        if (role == 10) {
                            this.router.navigate(['/docs/landers']);
                        } else if (role == 7) {
                            this.router.navigate(['/chatting']);
                        } else if (role == 8) {
                            this.router.navigate(['/chatting']);
                        } else if (role != 1) {
                            this.router.navigate(['/profiles']);
                        } else {
                            this.router.navigate(['/']);
                        }
                    }

                    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
                        if (token.isValid()) {
                            if (token.getPayload()['ott_cookie_id'] != '20231017a') {
                                this.router.navigate(['auth/login']);
                            }
                        } else {
                            this.router.navigate(['auth/login']);
                        }
                    });

                }),
            );
    }
}

@Injectable()
export class AuthGuardSystemManager implements CanActivate {

    constructor(private authService: NbAuthService,
                private tokenService: TokenService,
                private router: Router) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.authService.isAuthenticated()
            .pipe(
                tap(authenticated => {
                    if (!authenticated) {
                        this.router.navigate(['auth/login']);
                    } else {
                        let role = this.tokenService.role.getValue();
                        if ([4].includes(role) && state.url.startsWith('/dashboard-chatters')) {
                            return
                        }
                        // Allow access to /chatter-logs route for roles 7 and 8
                        if ([4, 7, 8].includes(role) && (state.url.startsWith('/stats/chatter-logs') || state.url.startsWith('/stats/chatter-stats'))) {
                            return
                        }
                        if ([4,8].includes(role) && state.url.startsWith('/stats/responses')) {
                            return
                        }

                        // restrict users with role = 20 to only /stats/traffic-live
                        if (role === 20) {
                            if (!state.url.startsWith('/stats/traffic-live')) {
                                this.router.navigate(['/stats/traffic-live']);
                            }
                            return;
                        }
                        // for role 3
                        if (role === 3) {
                            if (state.url.startsWith('/stats/traffic-live') || state.url.startsWith('/affiliates')) {
                                this.router.navigate(['/']);
                            }
                            return;
                        }

                        // escape for admin
                        if ([1, 2, 3].includes(role)) return;
                        if (role == 10) {
                            this.router.navigate(['/docs/landers']);
                        } else if ([7, 8, 4].includes(role)) {
                            this.router.navigate(['/chatting']);
                        } else if (role != 1) {
                            this.router.navigate(['/profiles']);
                        } else {
                            this.router.navigate(['/']);
                        }
                    }

                    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
                        if (token.isValid()) {
                            if (token.getPayload()['ott_cookie_id'] != '20231017a') {
                                this.router.navigate(['auth/login']);
                            }
                        } else {
                            this.router.navigate(['auth/login']);
                        }
                    });
                }),
            );
    }
}


@Injectable()
export class AuthGuardSystemChatters implements CanActivate {

    constructor(private authService: NbAuthService,
                private tokenService: TokenService,
                private router: Router) {
    }

    canActivate() {
        return this.authService.isAuthenticated()
            .pipe(
                tap(authenticated => {
                    if (!authenticated) {
                        this.router.navigate(['auth/login']);
                    } else {
                        let role = this.tokenService.role.getValue();
                        // escape for admin
                        if ([1, 2, 3, 4, 7, 8, 11].includes(role)) return;
                        else {
                            this.router.navigate(['/chatting']);
                        }
                    }

                    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
                        if (token.isValid()) {
                            if (token.getPayload()['ott_cookie_id'] != '20231017a') {
                                this.router.navigate(['auth/login']);
                            }
                        } else {
                            this.router.navigate(['auth/login']);
                        }
                    });
                }),
            );
    }
}


// external parties
@Injectable()
export class AuthGuardUsersVerification implements CanActivate {

    constructor(private authService: NbAuthService,
                private tokenService: TokenService,
                private router: Router) {
    }

    canActivate() {
        return this.authService.isAuthenticated()
            .pipe(
                tap(authenticated => {
                    if (!authenticated) {
                        this.router.navigate(['auth/login']);
                    } else {
                        let role = this.tokenService.role.getValue();
                        // escape for admin
                        if ([1, 2, 3, 4, 5, 7, 8, 11].includes(role)) return;
                        this.router.navigate(['/']);
                    }

                    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
                        if (token.isValid()) {
                            if (token.getPayload()['ott_cookie_id'] != '20231017a') {
                                this.router.navigate(['auth/login']);
                            }
                        } else {
                            this.router.navigate(['auth/login']);
                        }
                    });
                }),
            );
    }
}


// external party
@Injectable()
export class AuthGuardHelpdesk implements CanActivate {

    constructor(private authService: NbAuthService,
                private tokenService: TokenService,
                private router: Router) {
    }

    canActivate() {
        return this.authService.isAuthenticated()
            .pipe(
                tap(authenticated => {
                    if (!authenticated) {
                        this.router.navigate(['auth/login']);
                    } else {
                        let role = this.tokenService.role.getValue();
                        // escape for admin
                        if ([1, 2, 4, 5].includes(role)) return;
                        this.router.navigate(['/']);
                    }

                    this.authService.onTokenChange().subscribe((token: NbAuthJWTToken) => {
                        if (token.isValid()) {
                            if (token.getPayload()['ott_cookie_id'] != '20231017a') {
                                this.router.navigate(['auth/login']);
                            }
                        } else {
                            this.router.navigate(['auth/login']);
                        }
                    });

                }),
            );
    }
}
