<div class="header-container">
    <div class="logo-container">
        <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
            <nb-icon icon="menu-2-outline"></nb-icon>
        </a>
        <a class="logo" href="#" (click)="navigateHome()">OTT</a>
    </div>
</div>


<div class="header-container">
    <div *ngIf="showCount"
         class="mr-4 d-flex align-items-center wrap-block-count">
        <span class="mr-2">Pending chats: </span>
        <span *ngIf="pendingChatsCount$ | async; let count" [ngStyle]="{
                          'color'
                          : (count >= 10)? 'red'
                          : (count >= 5) ? '#ffaa00'
                          : (count < 5 &&  count > 0) ? 'green'
                          : 'rgba(0, 0, 0, .7)'}">
            {{count}}</span>
    </div>

    <nb-select
            *ngIf="user?.role_id === 7 || user?.role_id === 8"
            [selected]="user?.chatter_status_id"
            (selectedChange)="onChatterStatusChange($event)"
            class="status-dropdown"
            placeholder="Chatter Status"
    >
        <nb-option
                *ngFor="let status of this.chatterStatuses | async"
                [disabled]="disableOption(status.status)"
                [value]="status.status"
        >
            {{ status.name }}
        </nb-option>
    </nb-select>

    <nb-select style="min-width: 250px" *ngIf="affiliates.length > 0" [(selected)]="clientId"
               (selectedChange)="changeMainUser($event)">
        <nb-option *ngFor="let client of affiliates" [value]="client.id">{{client.alias}}</nb-option>
    </nb-select>


    <nb-actions size="small">

        <!--    <nb-action icon="message-square-outline" (click)="ticketDialog(0)"></nb-action>-->

        <nb-action class="user-action" *nbIsGranted="['view', 'user']">
            <nb-user
                    [nbContextMenu]="userMenu"
                    [color]="'#fff'"
                    [name]="user?.username"
                    [picture]="user?.image_main">
            </nb-user>
        </nb-action>
    </nb-actions>
</div>
